import axios from 'axios';
import store from '../../index';

export default {
  getMatchDetail(tourId, matchId, typeDispute, myTeamId) {
    const url = `${store.state.app.config.baseUrl}/get_match_detail`;

    return axios.get(url, {
      params: {
        tournament_id: tourId,
        match_id: matchId,
        type_dispute: typeDispute,
        my_team_id: myTeamId,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log('error', error);
      });
  },
};
