export const actions = {
  setAdminCreatorData({ commit }, state) {
    commit('SET_ADMIN_CREATOR_DATA', state);
  },
  setAdminState({ commit }, state) {
    commit('SET_ADMIN_STATE', state);
  },
  setAdminSubMenu({ commit }, state) {
    commit('SET_ADMIN_SUB_MENU', state);
  },
  setBracketData({ commit }, state) {
    commit('SET_BRACKET_DATA', state);
  },
  setKillRaceData({ commit }, state) {
    commit('SET_KILL_RACE_DATA', state);
  },
  setAdminModal({ commit }, state) {
    commit('SET_ADMIN_MODAL', state);
  },
  setTournamentSettingImport({ commit }, state) {
    commit('SET_TOURNAMENT_SETTING_IMPORT', state);
  },
};
