import axios from 'axios';
import store from '../../index';

export default {
  getListConnectedGames(username) {
    const url = `${store.state.app.config.baseUrl}/api/profile/my_activities/get_list_conected_games?name=` + username;

    return axios.get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {

      });
  },

  getProfileSetting(username) {
    const url = `${store.state.app.config.baseUrl}/api/profile/get_profile_setting_of_user?name=` + username;

    return axios.get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {

      });
  },

  getListSocialConnected(username) {
    const url = `${store.state.app.config.baseUrl}/api/profile/get_list_connected_social_account?name=` + username;

    return axios.get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {

      });
  },

  getListSupportGame() {
    const url = `${store.state.app.config.baseUrl}/api/get_list_support_game`;

    return axios.get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {

      });
  },
};
