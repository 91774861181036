export const mutations = {
  SET_LIST_CONFIRMED_SCRIMMAGE: (state, data) => {
    state.list_confirmed_scrimmage = data;
  },
  SET_LIST_PENDING_SCRIMMAGE: (state, data) => {
    state.list_pending_scrimmage = data;
  },
  SET_LIST_PAST_SCRIMMAGE: (state, data) => {
    state.list_past_scrimmage = data;
  },
  SET_LIST_UPCOMING_SCRIMMAGE: (state, data) => {
    state.list_upcoming_scrimmage = data;
  },
};
