export const actions = {
  setHubData({ commit }, hubData) {
    commit('SET_HUB_DATA', hubData);
  },
  setHubUserData({ commit }, hubUserData) {
    commit('SET_HUB_USER_DATA', hubUserData);
  },
  setCurrentTab({ commit }, tab) {
    commit('SET_CURRENT_TAB', tab);
  },
  setHubModal({ commit }, hubModal) {
    commit('SET_HUB_MODAL', hubModal);
  },
  setListHubLeaderInfo({ commit }, data) {
    commit('SET_LIST_HUB_LEADER_INFO', data);
  },
  setListHubAdminInfo({ commit }, data) {
    commit('SET_LIST_HUB_ADMIN_INFO', data);
  },
  setListHubMemberInfo({ commit }, data) {
    commit('SET_LIST_HUB_MEMBER_INFO', data);
  },
  setCountHubLeader({ commit }, data) {
    commit('SET_COUNT_HUB_LEADER', data);
  },
  setCountHubAdmin({ commit }, data) {
    commit('SET_COUNT_HUB_ADMIN', data);
  },
  setCountHubMember({ commit }, data) {
    commit('SET_COUNT_HUB_MEMBER', data);
  },
  setListHubTeamManagerInfo({ commit }, data) {
    commit('SET_LIST_HUB_TEAM_MANAGER_INFO', data);
  },
  setListHubTeamPlayerInfo({ commit }, data) {
    commit('SET_LIST_HUB_TEAM_PLAYER_INFO', data);
  },
  setCountHubTeamManager({ commit }, data) {
    commit('SET_COUNT_HUB_TEAM_MANAGER', data);
  },
  setCountHubTeamPlayer({ commit }, data) {
    commit('SET_COUNT_HUB_TEAM_PLAYER', data);
  },
  setShowModalConnectGameAccount({ commit }, status) {
    commit('SET_SHOW_MODAL_CONNECT_GAME_ACCOUNT', status);
  },
  setNewVersionHubData({ commit }, data) {
    commit('SET_NEW_VERSION_HUB_DATA', data);
  },
};
