export const actions = {
  setNumberRewards({ commit }, number) {
    commit('SET_NUMBER_REWARDS', number);
  },
  increaseNumberRewards({ commit }, number) {
    commit('INCREASE_NUMBER_REWARDS', number);
  },
  clearRewardReceived({ commit }) {
    commit('CLEAR_REWARDS_RECEIVED');
  },
  setRewardReceived({ commit }, data) {
    commit('SET_REWARDS_RECEIVED', data);
  },
  setNoRewardText({ commit }, text) {
    commit('SET_NO_REWARD_TEXT', text);
  },
  setShowDropsHighlight({ commit }, isShowing) {
    commit('SET_SHOW_DROPS_HIGHLIGHT', isShowing);
  },
};
