export const getters = {
  list_confirmed_scrimmage: (state) => {
    return state.list_confirmed_scrimmage;
  },
  list_pending_scrimmage: (state) => {
    return state.list_pending_scrimmage;
  },
  list_past_scrimmage: (state) => {
    return state.list_past_scrimmage;
  },
  list_upcoming_scrimmage: (state) => {
    return state.list_upcoming_scrimmage;
  },
};
