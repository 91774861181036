export function setBoomAlertUserSeen(username, timestamp) {
  if (username) {
    localStorage.setItem(`boom_alert_${username}_${timestamp}`, 'seen');
  }
}

export function checkBoomAlertUserSeen(username, timestamp) {
  return localStorage.getItem(`boom_alert_${username}_${timestamp}`);
}

export function saveNotificationIfRedirectPage(notifications) {
  localStorage.setItem('list_notification_queue', JSON.stringify(notifications));
}

export function getNotificationAfterBackPage() {
  const listQueueStorage = localStorage.getItem('list_notification_queue');
  if (listQueueStorage) {
    return JSON.parse(listQueueStorage);
  }
  return [];
}
