export const mutations = {
  SET_HUB_DATA: (state, hubData) => {
    state.hubData = hubData;
  },
  SET_HUB_USER_DATA: (state, hubUserData) => {
    state.hubUserData = hubUserData;
  },
  SET_CURRENT_TAB: (state, tab) => {
    state.currentTab = tab;
  },
  SET_HUB_MODAL: (state, hubModal) => {
    state.hubModal = hubModal;
  },
  SET_LIST_HUB_LEADER_INFO: (state, data) => {
    state.list_hub_leader_info = data;
  },
  SET_LIST_HUB_ADMIN_INFO: (state, data) => {
    state.list_hub_admin_info = data;
  },
  SET_LIST_HUB_TEAM_MANAGER_INFO: (state, data) => {
    state.list_hub_team_manager_info = data;
  },
  SET_LIST_HUB_TEAM_PLAYER_INFO: (state, data) => {
    state.list_hub_team_player_info = data;
  },
  SET_LIST_HUB_MEMBER_INFO: (state, data) => {
    state.list_hub_member_info = data;
  },
  SET_COUNT_HUB_TEAM_MANAGER: (state, data) => {
    state.count_hub_team_manager = data;
  },
  SET_COUNT_HUB_TEAM_PLAYER: (state, data) => {
    state.count_hub_team_player = data;
  },
  SET_COUNT_HUB_LEADER: (state, data) => {
    state.count_hub_leader = data;
  },
  SET_COUNT_HUB_ADMIN: (state, data) => {
    state.count_hub_admin = data;
  },
  SET_COUNT_HUB_MEMBER: (state, data) => {
    state.count_hub_member = data;
  },
  SET_SHOW_MODAL_CONNECT_GAME_ACCOUNT: (state, status) => {
    state.showModalConnectGameAccount = status;
  },
  SET_NEW_VERSION_HUB_DATA: (state, data) => {
    state.newVersionHubData = data;
  },
};
