export const getters = {
  hubData: (state) => {
    return state.hubData;
  },
  hubUserData: (state) => {
    return state.hubUserData;
  },
  currentTab: (state) => {
    return state.currentTab;
  },
  hubModal: (state) => {
    return state.hubModal;
  },
  list_hub_leader_info: (state) => {
    return state.list_hub_leader_info;
  },
  list_hub_admin_info: (state) => {
    return state.list_hub_admin_info;
  },
  list_hub_member_info: (state) => {
    return state.list_hub_member_info;
  },
  count_hub_leader: (state) => {
    return state.count_hub_leader;
  },
  count_hub_admin: (state) => {
    return state.count_hub_admin;
  },
  count_hub_member: (state) => {
    return state.count_hub_member;
  },
  list_hub_team_manager_info: (state) => {
    return state.list_hub_team_manager_info;
  },
  count_hub_team_manager: (state) => {
    return state.count_hub_team_manager;
  },
  list_hub_team_player_info: (state) => {
    return state.list_hub_team_player_info;
  },
  count_hub_team_player: (state) => {
    return state.count_hub_team_player;
  },
  showModalConnectGameAccount: (state) => {
    return state.showModalConnectGameAccount;
  },
  newVersionHubData: (state) => {
    return state.newVersionHubData;
  },
};
