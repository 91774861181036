export const state = {
  game_tournament: {},
  video_chat_status: 0,
  user_tournament_data: {},
  current_tournament_data: {},
  left_side_data: {},
  kill_race_standing_data: {},
  bracket_data: {},
  video_stream_data: { channel: 'boomtv', type_stream: 'twitch', show_multi_stream: false, multi_stream_data: {}},
  current_tab: 0,
  team_info: {},
  current_match_detail: {},
  action_register_data: {},
  modal_register_data: { tour_id: 0, modal: '', step: 0 },
  next_modal_register: [],
  show_br_custom_standings: false,
  hub_team_request_data: {},
};
