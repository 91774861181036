export const state = {
  config: {},
  errors: [],
  oldInputForm: [],
  laravel_data: {},
  is_loading_page: true,
  is_show_modal_login: false,
  is_show_modal_onboarding_student: false,
  is_show_modal_onboarding_non_student: false,
  is_show_mobile_deep_link_popup: false,
  is_show_modal_open_in_chrome: false,
};
