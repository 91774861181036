export const mutations = {
  SET_ADMIN_STATE: (state, adminState) => {
    state.adminState = adminState;
  },
  SET_ADMIN_CREATOR_DATA: (state, data) => {
    state.adminCreatorData = data;
  },
  SET_ADMIN_SUB_MENU: (state, subMenu) => {
    state.adminSubMenu = subMenu;
  },
  SET_BRACKET_DATA: (state, bracketData) => {
    state.bracketData = bracketData;
  },
  SET_KILL_RACE_DATA: (state, killRaceData) => {
    state.killRaceData = killRaceData;
  },
  SET_ADMIN_MODAL: (state, adminModal) => {
    state.adminModal = adminModal;
  },
  SET_TOURNAMENT_SETTING_IMPORT: (state, tournamentSettingImport) => {
    state.tournamentSettingImport = tournamentSettingImport;
  },
};
