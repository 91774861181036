import { actions } from './hub.actions';
import { getters } from './hub.getters';
import { mutations } from './hub.mutations';
import { state } from './hub.state';

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};
