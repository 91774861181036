<template>
  <div class="drops_works_card">
    <div
      :class="
        'drops_works_card_check ' +
          (quest.completionCount === quest.completionTarget ? 'check' : '')
      "
    >
      <img v-if="appConfig.isAvglDomain" class="lazyload" :data-src="contentCloudFront('/assets/drops/drops-check.svg')">
      <img v-else class="lazyload" :data-src="contentCloudFront('/assets/drops/drops-check-boom.svg')">
      {{ order + 1 }}
    </div>
    <div class="drops_works_card_title">
      {{ quest.title }}
    </div>
    <div class="drops_works_card_lines">
      <p
        v-for="(goal, index) in quest.goals"
        :key="index"
        :class="['drops_works_card_line',goal.isComplete ? 'goal-completed' : '', goal.action.type == 0 ? 'goal_none_action' : 'goal_action']"
      >
        <a v-if="!goal.isComplete && goal.action.type == 1" :href="appConfig.baseUrl + goal.action.data">{{ goal.description }}</a>
        <a v-else-if="!goal.isComplete && goal.action.type == 2 && goal.action.data == 'connect_account'" @click="eventShowModal('connect_account')">{{ goal.description }}</a>
        <a v-else-if="!goal.isComplete && goal.action.type == 2 && goal.action.data == 'verify_game_account'" @click="eventShowModal('verify_game_account')">{{ goal.description }}</a>
        <a v-else>{{ goal.description }}</a>
      </p>
    </div>
    <div class="drops_works_card_bar">
      <div
        class="drops_works_card_fill"
        style="transform: translate(-0%, 0);"
        :style="{
          transform:
            'translate(' +
            ((quest.completionCount / quest.completionTarget) * 100 - 100) +
            '%,0)',
        }"
      />
    </div>
    <div class="drops_works_card_info">
      <div class="section">
        <div class="drops_works_crown">
          <i class="sp-ic-sm-drops-crown" />
        </div>
        <p v-if="quest.completionCount === quest.completionTarget" class="drops_works_crown_title">
          You received:&nbsp;<span>{{ quest.rewardAmount }} {{ quest.rewardType }}</span>
        </p>
        <p v-else class="drops_works_crown_title">
          Reward:&nbsp;<span>{{ quest.rewardAmount }} {{ quest.rewardType }}</span>
        </p>
      </div>
      <p class="drops_works_crown_achievement">
        {{ quest.completionCount }}/{{ quest.completionTarget }}
      </p>
    </div>
  </div>
</template>

<script>
import { component } from '../../../mixins/component';
import { ModalMixin } from '../../../mixins/modal';
import { EventBus, GAME_ACCOUNT_CONNECTION_TOGGLE_MODAL } from '../../../event-bus';

export default {
  mixins: [component, ModalMixin],
  props: {
    quest: {
      type: Object,
      default() {
        return {
          completionCount: 0,
          completionTarget: 1,
          goals: [],
        };
      },
    },
    order: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    eventShowModal(type) {
      if (!this.isAuth) {
        this.showModalLogin();
        return;
      }
      if (type === 'verify_game_account') {
        // this.showModalProvideGameNameAdminApp();
        EventBus.$emit(GAME_ACCOUNT_CONNECTION_TOGGLE_MODAL, true);
      } else {
        this.showModalConnectSocialPlatform(this.routeDropsPage());
      }
    },
  },
};
</script>

<style>
  .goal-completed {
    text-decoration: line-through;
    display: list-item;
  }
  .goal-completed::before{
    content: none;
  }
</style>
