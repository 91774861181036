/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// eslint-disable-next-line
__webpack_public_path__ = window.resourceBasePath;

require('./bootstrap');

import Vue from 'vue';
Vue.config.devtools = true;
import store from './store';

// progress bar
import NProgress from 'nprogress';
// progress bar style
import 'nprogress/nprogress.css';

// NProgress Configuration
NProgress.configure({ showSpinner: false });

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('app', () => import(/* webpackChunkName: "app" */ './components/App'));
Vue.component('example', () => import(/* webpackChunkName: "example" */ './views/example/index'));
Vue.component('drops', () => import(/* webpackChunkName: "drops" */ './views/drops/index'));
Vue.component('codered-watch-rewards', () => import(/* webpackChunkName: "codered-watch-rewards" */ './components/drops/codered-watch-rewards/index'));
Vue.component('codered-drops', () => import(/* webpackChunkName: "codered-drops" */ './views/codered-drops/index'));
Vue.component('highschool-support', () => import(/* webpackChunkName: "highschool-support" */ './views/highschool-support/index'));
Vue.component('highschool-landing', () => import(/* webpackChunkName: "highschool-landing" */ './views/highschool-landing/index'));
Vue.component('highschool-landing-student', () => import(/* webpackChunkName: "highschool-landing-student" */ './views/highschool-landing/student/index'));
Vue.component('highschool-landing-admin', () => import(/* webpackChunkName: "highschool-landing-admin" */ './views/highschool-landing/admin/index'));
Vue.component('intel-inspires-login', () => import(/* webpackChunkName: "intel-inspires-login" */ './views/intel-inspires/login/index'));
Vue.component('intel-inspires-register', () => import(/* webpackChunkName: "intel-inspires-register" */ './views/intel-inspires/register/index'));
Vue.component('intel-inspires-unregister', () => import(/* webpackChunkName: "intel-inspires-register" */ './views/intel-inspires/unregister/index'));
Vue.component('intel-inspires-survey', () => import(/* webpackChunkName: "intel-inspires-survey" */ './views/intel-inspires/survey/index'));
Vue.component('intel-inspires-forgot-password', () => import(/* webpackChunkName: "intel-inspires-forgot-password" */ './views/intel-inspires/forgot-password/index'));
Vue.component('intel-inspires-reset-pasword', () => import(/* webpackChunkName: "intel-inspires-reset-password" */ './views/intel-inspires/reset-password/index'));
Vue.component('intel-recruiter-search', () => import(/* webpackChunkName: "intel-recruiter-search" */ './views/intel-inspires/recruiter-search/index'));
Vue.component('intel-recruiter-page', () => import(/* webpackChunkName: "intel-recruiter-page" */ './views/intel-inspires/recruiter-page/index'));
Vue.component('intel-inspires-dashboard', () => import(/* webpackChunkName: "intel-inspires-dashboard" */ './views/intel-inspires/dashboard/index'));
Vue.component('profile', () => import(/* webpackChunkName: "profile" */ './views/profile/index'));
Vue.component('event-calendar-view', () => import(/* webpackChunkName: "event-calendar" */ './views/event-calendar/index'));
Vue.component('error_404', () => import(/* webpackChunkName: "error_404" */ './views/profile/error_404'));
Vue.component('how-it-works', () => import(/* webpackChunkName: "how-it-works" */ './views/how-it-works/index'));

Vue.component('landing', () => import(/* webpackChunkName: "landing" */ './views/landing/index'));
Vue.component('modal-future-creator', import(/* webpackChunkName: "modal-future-creator" */ './views/landing/ModalFutureCreator'));
Vue.component('calendar-filter', () => import(/* webpackChunkName: "calendar-filter" */ './components/shared/CalendarFilter'));
Vue.component('calendar-marquees', () => import(/* webpackChunkName: "calendar-marquees" */ './components/shared/CalendarMarquees'));
Vue.component('event-calendar', () => import(/* webpackChunkName: "event-calendar" */ './components/events/EventCalendar'));
Vue.component('app-header', () => import(/* webpackChunkName: "app-header" */ './components/header/index'));
Vue.component('app-modals', () => import(/* webpackChunkName: "app-modals" */ './views/modals/index'));
Vue.component('app-footer', () => import(/* webpackChunkName: "app-footer" */ './components/footer/index'));
Vue.component('app-socket', () => import(/* webpackChunkName: "app-socket" */ './components/socketIO/index'));
Vue.component('notification-item', () => import(/* webpackChunkName: "notification-item" */ './components/notifications/NotificationItem'));
Vue.component('top-menu-hamburger', () => import(/* webpackChunkName: "top-menu-hamburger" */ './components/shared/menus/top-menu-hamburger'));
Vue.component('top-menu-profile-hamburger', () => import(/* webpackChunkName: "top-menu-profile-hamburger" */ './components/shared/menus/top-menu-profile-hamburger'));
Vue.component('top-menu-logo-hamburger', () => import(/* webpackChunkName: "top-menu-logo-hamburger" */ './components/shared/menus/top-menu-logo-hamburger'));
Vue.component('top-menu-toggle', () => import(/* webpackChunkName: "top-menu-toggle" */ './components/shared/menus/top-menu-toggle'));
Vue.component('game-account-modal', () => import(/* webpackChunkName: "game-account-modal" */ './components/shared/GameAccountsModal'));
Vue.component('init-socket', () => import(/* webpackChunkName: "init-socket" */ './components/shared/InitSocket'));
Vue.component('modal-boom-confirm', () => import(/* webpackChunkName: "modal-boom-confirm" */ './components/modals/ModalBoomConfirm'));
Vue.component('modal-boom-message', () => import(/* webpackChunkName: "modal-boom-message" */ './components/modals/ModalBoomMessage'));
Vue.component('modal-login', () => import(/* webpackChunkName: "modal-login" */ './components/modals/ModalLogin'));
Vue.component('modal-login-twitch', () => import(/* webpackChunkName: "modal-login-twitch" */ './components/modals/ModalLoginTwitch'));
Vue.component('streamer-login', () => import(/* webpackChunkName: "streamer-login" */ './components/shared/StreamerLogin'));

Vue.component('sub-tourneys', () => import(/* webpackChunkName: "sub-tourneys" */ './views/sub-tourneys/index'));
Vue.component('checkout-success', () => import(/* webpackChunkName: "checkout-success" */ './views/checkout/success/index'));

Vue.component('queueup-consumer-landing', () => import(/* webpackChunkName: "queueup-consumer-landing" */ './views/queueup/consumer-landing/index'));
Vue.component('queueup-dashboard-my-queue', () => import(/* webpackChunkName: "queueup-dashboard-my-queue" */ './views/queueup/dashboard-my-queue/index'));
Vue.component('queueup-dashboard-all-coaches', () => import(/* webpackChunkName: "queueup-dashboard-all-coaches" */ './views/queueup/dashboard-all-coaches/index'));
Vue.component('queueup-provider-register', () => import(/* webpackChunkName: "queueup-provider-register" */ './views/queueup/provider-register/index'));
Vue.component('queueup-provider-profile', () => import(/* webpackChunkName: "queueup-provider-profile" */ './views/queueup/provider-profile/index'));
Vue.component('queueup-partner-order', () => import(/* webpackChunkName: "queueup-partner-order" */ './views/queueup/partner-order/index'));
Vue.component('queueup-top-menu', () => import(/* webpackChunkName: "queueup-top-menu" */ './components/queueup/menus/top-menu'));
Vue.component('queueup-lhs-menu', () => import(/* webpackChunkName: "queueup-lhs-menu" */ './components/queueup/menus/lhs-menu'));
Vue.component('queueup-survey-modals', () => import(/* webpackChunkName: "queueup-survey-modals" */ './components/queueup/survey-modals/index'));

Vue.component('tournament-pages', () => import(/* webpackChunkName: "tournament-pages" */ './views/tournament_pages/index'));
Vue.component('new-events', () => import(/* webpackChunkName: "new-events" */ './views/events/index.vue'));

Vue.component('admin-creator', () => import(/* webpackChunkName: "admin-creator" */ './views/admin-creator/index'));
Vue.component('admin-reset-password', () => import(/* webpackChunkName: "admin-reset-password" */ './views/admin-creator/reset-password'));
Vue.component('admin-reset-password-outdated', () => import(/* webpackChunkName: "admin-reset-password-outdated" */ './views/admin-creator/reset-password/outdated'));

Vue.component('hub-drop-featured-event', () => import(/* webpackChunkName: "hub-drop-featured-event" */ './components/hubs/drops/FeatureEvent.vue'));
Vue.component('hub-challenge', () => import(/* webpackChunkName: "hub-challenge" */ './components/hubs/drops/HubChallenge.vue'));
Vue.component('hub-event-slider', () => import(/* webpackChunkName: "hub-event-slider" */ './components/hubs/events/EventSlider.vue'));
Vue.component('hub-slider', () => import(/* webpackChunkName: "hub-slider" */ './components/hubs/events/HubSlider.vue'));
Vue.component('hub-top-plays', () => import(/* webpackChunkName: "hub-top-plays" */ './components/hubs/top-plays/TopPlays.vue'));
Vue.component('hub-tweets', () => import(/* webpackChunkName: "hub-tweets" */ './components/hubs/tweets/Tweets.vue'));
Vue.component('hub-drop-leaderboard', () => import(/* webpackChunkName: "hub-drop-leaderboard" */ './components/hubs/leaderboard/DropLeaderboard.vue'));

Vue.component('event-highlights', () => import(/* webpackChunkName: "event-highlights" */ './components/events/highlights/index'));
Vue.component('new-events', () => import(/* webpackChunkName: "new-events" */ './views/events/index.vue'));
Vue.component('quest-rewards', () => import(/* webpackChunkName: "quest-rewards" */ './views/drops/quest-rewards/index.vue'));
Vue.component('quest-rewards-hub', () => import(/* webpackChunkName: "quest-rewards-hub" */ './views/drops/quest-rewards-hub/index.vue'));
Vue.component('hubs', () => import(/* webpackChunkName: "hubs" */ './views/hubs/index.vue'));

Vue.component('web-page-search', () => import(/* webpackChunkName: "quest-rewards-hub" */ './views/search/search_page.vue'));
Vue.component('search-bar', () => import(/* webpackChunkName: "quest-rewards-hub" */ './views/search/index.vue'));
Vue.component('event-series', () => import(/* webpackChunkName: "series" */ './views/event-series/index.vue'));

Vue.component('personal-data', () => import(/* webpackChunkName: "personal-data" */ './views/home/personal-data.vue'));
Vue.component('privacy', () => import(/* webpackChunkName: "privacy" */ './views/home/privacy.vue'));
Vue.component('about', () => import(/* webpackChunkName: "about" */ './views/home/about.vue'));
Vue.component('terms', () => import(/* webpackChunkName: "terms" */ './views/home/terms.vue'));
Vue.component('request-hub', () => import(/* webpackChunkName: "request-hub" */ './views/home/request-hub.vue'));
Vue.component('trending-video', () => import(/* webpackChunkName: "trending-video" */ './views/home/trending-video.vue'));
Vue.component('official-rules', () => import(/* webpackChunkName: "official-rules" */ './views/home/official-rules.vue'));
Vue.component('dmca', () => import(/* webpackChunkName: "dmca" */ './views/home/dmca.vue'));
Vue.component('contact', () => import(/* webpackChunkName: "contact" */ './views/home/contact.vue'));
Vue.component('careers', () => import(/* webpackChunkName: "careers" */ './views/home/careers.vue'));

import { setSocialAttributionData } from './utils/attribution-tracking';
import { getSocialAttributionData } from './utils/attribution-tracking';
import { saveEventAnalyticsData } from './utils/attribution-tracking';
window.getSocialAttributionData = getSocialAttributionData;
window.setSocialAttributionData = setSocialAttributionData;
window.saveEventAnalyticsData = saveEventAnalyticsData;
window.VueQuestRewards = require('./views/drops/quest-rewards/index.vue');
window.VueStore = store;
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

new Vue({
  el: '#vue-app',
  store,
});
