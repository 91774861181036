export const mutations = {
  SET_CONFIG: (state, config) => {
    state.config = config;
  },
  SET_ERROR: (state, errors) => {
    state.errors = errors;
  },
  SET_OLD_INPUT: (state, oldInputForm) => {
    state.oldInputForm = oldInputForm;
  },
  SET_LARAVEL_DATA: (state, data) => {
    state.laravel_data = data;
  },
  SET_START_LOAD_PAGE: (state) => {
    state.is_loading_page = true;
  },
  SET_PAGE_LOADED: (state) => {
    state.is_loading_page = false;
  },
  SET_IS_SHOWING_LOGIN_MODAL: (state, isShowing) => {
    state.is_show_modal_login = isShowing;
  },
  SET_IS_SHOWING_ON_BOARDING_STUDENT_MODAL: (state, isShowing) => {
    state.is_show_modal_onboarding_student = isShowing;
  },
  SET_IS_SHOWING_ON_BOARDING_NON_STUDENT_MODAL: (state, isShowing) => {
    state.is_show_modal_onboarding_non_student = isShowing;
  },
  UPDATE_LARAVEL_DATA: (state, data) => {
    state.laravel_data = {
      ...state.laravel_data,
      ...data,
    };
  },
  SET_SHOW_MOBILE_DEEPLINK_POPUP: (state, show) => {
    state.is_show_mobile_deep_link_popup = show;
  },
  SET_SHOW_MODAL_OPEN_IN_CHROME: (state, show) => {
    state.is_show_modal_open_in_chrome = show;
  },
};
