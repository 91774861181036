/**
 * If the url has format https://boom.tv?utm_medium=medium&utm_source=source&utm_campaign=cam
 * We will store medium, source, cam in local storage for future use
 */
export function setSocialAttributionData() {
  if (typeof window.URLSearchParams === 'function') {
    const params = new URLSearchParams(window.location.search);
    if (params.has('utm_medium')) { // if this has pixel tracking
      localStorage.setItem('b_medium', params.get('utm_medium'));
      localStorage.setItem('b_source', params.get('utm_source'));
      localStorage.setItem('b_campaign', params.get('utm_campaign'));
      localStorage.setItem('b_ts', Date.now());
    }
  }
}

/**
 * Get utm data from local storage
 * If the ts is too old (more than 10 minutes) return empty string.
 * @returns hash with four key: medium, source, campaign, ts
 */
export function getSocialAttributionData() {
  var ts = Number(localStorage.getItem('b_ts'));
  var now = Date.now();
  if (now - ts <= 10 * 60 * 1000) { // if ts is within the last 10 minutes
    return {
      medium: localStorage.getItem('b_medium'),
      source: localStorage.getItem('b_source'),
      campaign: localStorage.getItem('b_campaign'),
      ts: localStorage.getItem('b_ts'),
    };
  } else {
    return {
      medium: '',
      source: '',
      campaign: '',
      ts: '',
    };
  }
}

/**
 * save social analytics to db when user click register a tournament
 * @param event
 * @param additionalData
 */
export function saveEventAnalyticsData(event, csrfToken, additionalData = {}) {
  var getAttributionData = getSocialAttributionData();

  // only save when source has value (facebook, tiktok...)
  if (!getAttributionData['source']) {
    return;
  }

  var dataSave = Object.assign({
    additional_data: JSON.stringify(additionalData),
    event: event,
  }, getAttributionData);
  window.Vue.prototype.$http.post('/analytics/save-event-analytics-data', dataSave, {
    headers: {
      'X-CSRF-TOKEN': csrfToken,
    },
  })
    .then(response => {
      console.log(response.data.msg);
    })
    .catch(error => {
      console.log(error);
    });
}
