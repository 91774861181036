export const state = {
  user: null,
  targetUser: null,
  section_data: {},
  extra_data: {},
  hub_data: {},
  connect_platform_data: null,
  connectedGames: null,
  profileSettings: null,
  socialConnected: null,
  listSupportGame: null,
  profileUserExtraData: null,
};
