import { actions } from './rewards.actions';
import { getters } from './rewards.getters';
import { mutations } from './rewards.mutations';
import { state } from './rewards.state';

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};
