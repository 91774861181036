export const mutations = {
  SET_USER: (state, user) => {
    state.user = user;
  },
  SET_TARGET_USER: (state, targetUser) => {
    state.targetUser = targetUser;
  },
  SET_USER_AS_TARGET_USER: (state) => {
    state.targetUser = state.user;
  },
  SET_SECTION_DATA: (state, data) => {
    state.section_data = data;
  },
  SET_EXTRA_DATA: (state, data) => {
    state.extra_data = data;
  },
  SET_HUB_DATA: (state, data) => {
    state.hub_data = data;
  },
  SET_CONNECT_PLATFORM_DATA: (state, data) => {
    state.connect_platform_data = data;
  },
  UPDATE_USER: (state, data) => {
    state.user = {
      ...state.user,
      ...data,
    };
  },
  UPDATE_EXTRA_DATA: (state, data) => {
    state.extra_data = {
      ...state.extra_data,
      ...data,
    };
  },
  UPDATE_TARGET_USER: (state, data) => {
    state.targetUser = {
      ...state.targetUser,
      ...data,
    };
  },
  SET_CONNECTED_GAMES: (state, data) => {
    /* const connectedGames = {};
    for (const key of Object.keys(data)) {
      connectedGames[data[key].alias] = data[key];
    }*/
    state.connectedGames = data;
  },
  SET_PROFILE_SETTINGS: (state, data) => {
    state.profileSettings = data;
  },
  SET_SOCIAL_CONNECTED: (state, data) => {
    state.socialConnected = data;
  },
  GET_LIST_SUPPORT_GAME: (state, data) => {
    state.listSupportGame = data;
  },
  SET_PROFILE_USER_EXTRA_DATA: (state, data) => {
    state.profileUserExtraData = data;
  },
  UPDATE_PROFILE_USER_EXTRA_DATA: (state, data) => {
    state.profileUserExtraData = {
      ...state.profileUserExtraData,
      ...data,
    };
  },
};
