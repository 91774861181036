export const actions = {
  setListConfirmedScrimmage({ commit }, data) {
    commit('SET_LIST_CONFIRMED_SCRIMMAGE', data);
  },
  setListPendingScrimmage({ commit }, data) {
    commit('SET_LIST_PENDING_SCRIMMAGE', data);
  },
  setListPastScrimmage({ commit }, data) {
    commit('SET_LIST_PAST_SCRIMMAGE', data);
  },
  setListUpcomingScrimmage({ commit }, data) {
    commit('SET_LIST_UPCOMING_SCRIMMAGE', data);
  },
};
