<template>
  <div class="drops_works">
    <div class="drops_works_header">
      <div class="drops_works_title">
        <i v-if="appConfig.isAvglDomain" class="sp-ic-sm-drops-arrows" />
        <i v-else class="sp-ic-sm-drops-arrows-boom" />
        <p class="drops_works_title_text">
          How to
        </p>
        <div v-if="userData.theme === 'dark'">
          <i v-if="appConfig.isAvglDomain" class="sp-ic-sm-drops-win" />
          <i v-else class="sp-ic-sm-drops-win-boom" />
        </div>
        <div v-else>
          <i v-if="appConfig.isAvglDomain" class="sp-ic-sm-drops-win-boom-light-theme" />
          <i v-else class="sp-ic-sm-drops-win-boom-light-theme" />
        </div>
      </div>
      <p class="drops_works_desc">
        Crowns and cakes enter you into the drop for the month! Make sure you
        smash your cakes to get the maximum amount of entries. For more
        information, read the
        <a :href="appConfig.baseUrl + '/drops-faq'">Drops FAQ</a>
      </p>
    </div>
    <!--	SLIDER START	-->
    <div class="landing_slider">
      <img
        v-if="isLoading"
        id="leaderboard_loading_img"
        class="lazyload"
        :data-src="contentCloudFrontWithVersion('/ellipsis.svg')"
        style="width: 100%; height: 100px; position: relative;"
      >
      <div
        ref="quests_slick"
        class="variable-width"
        data-slick="{&quot;infinite&quot;: false, &quot;speed&quot;: 300, &quot;slidesToShow&quot;: 3, &quot;centerMode&quot;: false, &quot;variableWidth&quot;: true, &quot;initialSlide&quot;: 0, &quot;draggable&quot;: true, &quot;responsive&quot;: [ {&quot;breakpoint&quot;: 1000, &quot;settings&quot;: {&quot;slidesToShow&quot;: 2, &quot;slidesToScroll&quot;: 1}}, {&quot;breakpoint&quot;: 576, &quot;settings&quot;: {&quot;slidesToShow&quot;: 1, &quot;slidesToScroll&quot;: 1}}]}"
      >
        <quest-card
          v-for="(quest, index) in quests"
          :key="index"
          :quest="quest"
          :order="index"
        />
      </div>
    </div>
    <!--	SLIDER END	-->
  </div>
</template>

<script>
import { component } from '../../../mixins/component';
import QuestCard from './QuestCard';
import 'slick-carousel/slick/slick.min';
import Vue from 'vue';
import jQuery from 'jquery';
import { EventBus, NOTIFICATION_CONNECT_3P_ACCOUNT, NOTIFICATION_VERIFY_GAME_NAME } from '../../../event-bus';

export default {
  components: { QuestCard },
  mixins: [component],
  data() {
    return {
      quests: {},
      isLoading: true,
      crowns: 0,
    };
  },
  created() {
    EventBus.$on(NOTIFICATION_VERIFY_GAME_NAME, this.refreshQuest);
    EventBus.$on(NOTIFICATION_CONNECT_3P_ACCOUNT, this.refreshQuest);
    this.$http
      .get(this.routeDropsGetDropsPageData())
      .then((response) => {
        console.log(response.data);
        if (response.data.status === 0) {
          this.crowns = response.data.crowns;
          this.quests = response.data.quests;
          Vue.nextTick(() => {
            this.initSlick();
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  beforeDestroy() {
    EventBus.$off(NOTIFICATION_VERIFY_GAME_NAME);
    EventBus.$off(NOTIFICATION_CONNECT_3P_ACCOUNT);
  },
  methods: {
    initSlick() {
      jQuery(this.$refs.quests_slick).slick({
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        centerMode: false,
        variableWidth: true,
        initialSlide: 0,
        draggable: true,
        responsive: [
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
      var slideCount = 0;
      jQuery(this.$refs.quests_slick)
        .find('.slick-prev')
        .first()
        .addClass('btn_slick_disable');
      jQuery(this.$refs.quests_slick).on('beforeChange', function(
        event,
        slick,
        current_slide,
        nextSlide
      ) {
        if (nextSlide === 0) {
          jQuery(this).find('.slick-prev').first().addClass('btn_slick_disable');
        } else {
          jQuery(this).find('.slick-prev').first().removeClass('btn_slick_disable');
        }
      });
      jQuery(this.$refs.quests_slick).on('afterChange', function(
        event,
        slick,
        current_slide
      ) {
        var slide_show = slick.slickGetOption('slidesToShow');
        slideCount = jQuery(this).find('.drops_works_card').length;
        if (current_slide > slideCount - slide_show - 1) {
          jQuery(this).find('.slick-next').first().addClass('btn_slick_disable');
        } else {
          jQuery(this).find('.slick-next').first().removeClass('btn_slick_disable');
        }
      });
    },
    destroySlick() {
      jQuery(this.$refs.quests_slick).slick('unslick');
    },
    refreshQuest() {
      this.$http
        .get(this.routeDropsGetDropsPageData())
        .then((response) => {
          console.log(response.data);
          if (response.data.status === 0) {
            this.destroySlick();
            this.crowns = response.data.crowns;
            this.quests = response.data.quests;
            Vue.nextTick(() => {
              this.initSlick();
            });
          }
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
