import { actions } from './tournament.actions';
import { getters } from './tournament.getters';
import { mutations } from './tournament.mutations';
import { state } from './tournament.state';

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};
