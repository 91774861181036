import { actions } from './admin-creator.actions';
import { getters } from './admin-creator.getters';
import { mutations } from './admin-creator.mutations';
import { state } from './admin-creator.state';

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};
