export const SOURCE_THIRD_PARTY = 6;

export const helper = {
  methods: {
    isNumeric(value) {
      return /^-?\d+$/.test(value);
    },
    validateInputNumber() {
      const element = event.target;
      const value = element.value;
      let min;
      let max;
      const attrMin = element.getAttribute('data-min-number');
      const attrMax = element.getAttribute('data-max-number');
      const attrFloatNumber = element.getAttribute('data-float-number');
      if (typeof attrMin !== typeof undefined && attrMin !== false) {
        min = attrMin;
      }
      if (typeof attrMax !== typeof undefined && attrMax !== false) {
        max = attrMax;
      }
      /**
           *  check whether the value which is input
           *      - not a number (example: 'aaa', 'as223s'): prevent input
           *      - example: '2a' => 2
           *      - number => number
           */
      if (attrFloatNumber === 'true' && value.includes('.')) {
        var parts = value.split('.');
        var natural = parts[0];
        var tithes = parts[1];
        if (parts.length > 2) {
          event.target.value = natural + '.' + tithes;
        } else {
          if (tithes !== '' && !this.isNumeric(tithes)) {
            event.target.value = natural;
          }
        }
      } else {
        if (isNaN(parseFloat(value))) {
          event.target.value = '';
        } else {
          event.target.value = parseFloat(value);
        }
      }
      /**
           *  check whether the value which is input:
           *      - > data-max: value = data-max
           *      - < data-min: value = data-min
           */
      if (min !== undefined && parseFloat(value) < parseFloat(min)) {
        event.target.value = min;
      }
      if (max !== undefined && parseFloat(value) > parseFloat(max)) {
        event.target.value = max;
      }
    },
    checkMobileDevice() {
      if (/android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },
    checkIpadDevice() {
      if (/iPad/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },
    checkIOSDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
      }
      return false;
    },
    checkAndroidDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return true;
      }
      return false;
    },
    capitalizeString(s) {
      if (typeof s !== 'string') {
        return '';
      }
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    getEducationLevelString(educationLevel) {
      if (educationLevel === 'high_school') {
        return 'High School';
      } else if (educationLevel === 'college') {
        return 'College';
      } else if (educationLevel === 'none_student') {
        return 'Non-Student';
      }
      return '';
    },
    checkLightTheme() {
      const theme = localStorage.getItem('theme');
      if (theme === 'light') {
        return true;
      }
      return false;
    },
    convertTextToHyperLink(input) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return input.replace(urlRegex, function(url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
      });
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    shareTwitter(textShare) {
      var link_share_twitter = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(textShare);
      console.log(link_share_twitter);
      window.open(link_share_twitter, '_blank', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
    },
    convertRankToString(rank) {
      if (rank <= 0) {
        rank = '-';
      } else {
        var mod = rank % 10;
        if (mod === 1 && rank !== 11) {
          rank += 'st';
        } else if (mod === 2 && rank !== 12) {
          rank += 'nd';
        } else if (mod === 3 && rank !== 13) {
          rank += 'rd';
        } else {
          rank += 'th';
        }
      }
      return rank;
    },
    setBrowserTitle() {
      const hash = window.location.hash;
      if (hash === '') {
        window.document.title = 'Profile';
        return;
      }
      if (hash.includes('queue_sessions')){
        window.document.title = 'QueueUP - Sessions';
      } else if (hash.includes('queue_orders')) {
        window.document.title = 'QueueUP - Orders & Lobbies';
      } else if (hash.includes('wallet')) {
        window.document.title = 'Wallet';
      } else if (hash === '#overview') {
        window.document.title = 'Profile Overview';
      } else if (hash.includes('statistics')) {
        window.document.title = 'My Statistics';
      } else if (hash.includes('my_activities')) {
        window.document.title = 'My Activities';
      } else if (hash === '#settings' || hash === '#settings_profile') {
        window.document.title = 'Profile Settings';
      } else if (hash === '#settings_rewards') {
        window.document.title = 'My Rewards';
      } else if (hash === '#settings_connections') {
        window.document.title = 'Connection Settings';
      } else if (hash === '#settings_public_data') {
        window.document.title = 'Public Data Settings';
      } else if (hash.includes('queue')) {
        window.document.title = 'QueueUp Profile';
      }
    },
    /**
     *
     * @param {string} source  (default size 300x300)
     * @param {string} size  (600x600, 300x300, 150x150, 70x70, 50x50, 28x28)
     */
    getTwitchAvatarUrl(source, size) {
      if (source && source.includes('static-cdn.jtvnw.net')) {
        return source.replace('300x300', size);
      }
      if (!source) {
        return this.getDefaultAvatar();
      }
      return source;
    },
    /**
     * Certain twitch api returns thumbnail with %{width}x%{height}
     * This will replace that with size provided by caller
     * "https://static-cdn.jtvnw.net/cf_vods/d1m7jfoe9zdc1j/82c4b97c6a8f1d6b888d_shroud_43871830509_1632840862//thumb/thumb0-%{width}x%{height}.jpg"
     * @param {string} source a thumbnail url from Twitch
     * @param {string} size widthxheight for example 300x300
     */
    getTwitchThumbnailUrl(source, size) {
      if (source) {
        return source.replace('%{width}x%{height}', size);
      }
      return source;
    },
    decodeHtmlEntities(str){
      var element = document.createElement('div');
      if (str && typeof str === 'string') {
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = '';
      }

      return str;
    },
    get_cookie(name) {
      return document.cookie.split(';').some(c => {
        return c.trim().startsWith(name + '=');
      });
    },
    delete_cookie(name, path, domain) {
      if (this.get_cookie(name)) {
        document.cookie = name + '=' +
                  ((path) ? ';path=' + path : '') +
                  ((domain) ? ';domain=' + domain : '') +
                  ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
      }
    },
    covertColorFromHexToHsl(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

      var r = parseInt(result[1], 16);
      var g = parseInt(result[2], 16);
      var b = parseInt(result[3], 16);

      r /= 255;
      g /= 255;
      b /= 255;
      var max = Math.max(r, g, b), min = Math.min(r, g, b);
      var h, s, l = (max + min) / 2;

      if (max === min){
        h = s = 0;
      } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r: h = (g - b) / d + (g < b ? 6 : 0); break;
          case g: h = (b - r) / d + 2; break;
          case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
      }

      s = s * 100;
      s = Math.round(s);
      l = l * 100;
      l = Math.round(l);
      h = Math.round(360 * h);
      return [h, s, l];
    },
    convertColorFromHslToHex(h, s, l) {
      l /= 100;
      const a = s * Math.min(l, 1 - l) / 100;
      const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');
      };
      return `#${f(0)}${f(8)}${f(4)}`;
    },
    convertColorFromHexToRgb(hex) {
      var bigint = parseInt(hex.replace('#', ''), 16);
      var r = (bigint >> 16) & 255;
      var g = (bigint >> 8) & 255;
      var b = bigint & 255;
      return r + ',' + g + ',' + b;
    },
    boomValidateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    convertTimeDistanceToString(timestamp, numberDigits = 0) {
      if (timestamp <= 0) {
        return '0 seconds';
      }
      const units = {
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      const listUnitKey = Object.keys(units);
      let strs = [];
      for (let i = 0; i < listUnitKey.length; i++) {
        if (timestamp < units[listUnitKey[i]]) {
          continue;
        }
        var num = parseInt(timestamp / units[listUnitKey[i]]);
        timestamp = timestamp % units[listUnitKey[i]];
        strs.push(num + ' ' + listUnitKey[i] + (num === 1 ? '' : 's'));
      }
      if (numberDigits) {
        strs = strs.slice(0, numberDigits);
      }
      return strs.join(', ');
    },
    slugify(text) {
      return text.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
    },
    stripHtml(html) {
      const tmp = document.createElement('DIV');
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || '';
    },
    getUserTypeString(type) {
      let typeString;
      switch (type) {
        case this.USER_TYPES.YOUTUBE:
        case this.USER_TYPES.GOOGLE:
          typeString = 'youtube';
          break;
        case this.USER_TYPES.EMAIL:
          typeString = 'email';
          break;
        case this.USER_TYPES.TWITTER:
          typeString = 'twitter';
          break;
        case this.USER_TYPES.APPLE:
          typeString = 'apple';
          break;
        case this.USER_TYPES.MIXER:
          typeString = 'mixer';
          break;
        default:
          typeString = 'twitch';
          break;
      }
      return typeString;
    },
    stripString(string) {
      return string.replace(/(<([^>]+)>)/gi, '');
    },
    removeAllSpaces(string) {
      return string.replaceAll(/\s/g, '');
    },
  },
};
