export const getters = {
  listGameTournament: (state) => {
    return state.game_tournament;
  },
  getVideoChatStatus: (state) => {
    return state.video_chat_status;
  },
  userTournamentData: (state) => {
    return state.user_tournament_data;
  },
  currentTournamentData: (state) => {
    return state.current_tournament_data;
  },
  leftSideData: (state) => {
    return state.left_side_data;
  },
  killRaceStandingData: (state) => {
    return state.kill_race_standing_data;
  },
  bracketData: (state) => {
    return state.bracket_data;
  },
  videoStreamData: (state) => {
    return state.video_stream_data;
  },
  currentTabTournament: (state) => {
    return state.current_tab;
  },
  teamInfo: (state) => {
    return state.team_info;
  },
  currentMatchDetail: (state) => {
    return state.current_match_detail;
  },
  actionRegisterData: (state) => {
    return state.action_register_data;
  },
  modalRegisterData: (state) => {
    return state.modal_register_data;
  },
  nextModalRegister: (state) => {
    return state.next_modal_register;
  },
  showBrCustomStandings: (state) => {
    return state.show_br_custom_standings;
  },
  hubTeamRequestData: (state) => {
    return state.hub_team_request_data;
  },
};
