export const mutations = {
  SET_GAME_PLATFORM: (state, gamePlatform) => {
    state.game_tournament = { ...gamePlatform };
  },
  SET_VIDEO_CHAT_STATUS: (state, status) => {
    state.video_chat_status = status;
  },
  SET_USER_TOURNAMENT_DATA: (state, userTournamentData) => {
    state.user_tournament_data = userTournamentData;
  },
  SET_CURRENT_TOURNAMENT_DATA: (state, tournamentData) => {
    state.current_tournament_data = tournamentData;
  },
  SET_LEFT_SIDE_DATA: (state, leftSideData) => {
    state.left_side_data = leftSideData;
  },
  SET_BRACKET_DATA: (state, bracketData) => {
    state.bracket_data = bracketData;
  },
  SET_VIDEO_STREAM_DATA: (state, videoStreamData) => {
    state.video_stream_data = videoStreamData;
  },
  SET_CURRENT_TAB_TOURNAMENT: (state, currentTab) => {
    state.current_tab = currentTab;
  },
  SET_TEAM_INFO: (state, teamInfo) => {
    state.team_info = teamInfo;
  },
  SET_MATCH_DETAIL: (state, matchDetail) => {
    state.current_match_detail = matchDetail;
  },
  SET_ACTION_REGISTER_DATA: (state, actionRegisterData) => {
    state.action_register_data = actionRegisterData;
  },
  SET_MODAL_REGISTER_DATA: (state, modalRegisterData) => {
    state.modal_register_data = modalRegisterData;
  },
  SET_NEXT_MODAL_REGISTER: (state, nextModalRegister) => {
    state.next_modal_register = nextModalRegister;
  },
  SET_SHOW_BR_CUSTOM_STANDINGS: (state, showBrCustomStading) => {
    state.show_br_custom_standings = showBrCustomStading;
  },
  SET_HUB_TEAM_REQUEST_DATA: (state, hubTeamRequestData) => {
    state.hub_team_request_data = hubTeamRequestData;
  },
  SET_KILL_RACE_STANDING_DATA: (state, killRaceStandingData) => {
    state.kill_race_standing_data = killRaceStandingData;
  },
};
