import {
  EventBus,
  SHOW_MODAL_CONNECT_ACCOUNTS,
  SHOW_MODAL_CONNECT_SOCIAL_PLATFORM,
  SHOW_MODAL_LOGIN,
  SHOW_MODAL_ONBOARDING_INITIAL,
  SHOW_MODAL_BOOM_CONFIRM,
  SHOW_MODAL_BOOM_MESSAGE,
} from '../event-bus';
import { UserMixin } from './user';
import { component } from './component';
import { SOURCE_THIRD_PARTY } from './helper';

export const ModalMixin = {
  mixins: [UserMixin, component],
  created() {
    this.$root.$refs.ModalMixin = this;
  },
  methods: {
    showModalLogin(redirectUrl = '', sourceRegister = '', isLogin = false, redirectAfterClose = '') {
      if (this.appConfig.oauth2_info.is_oauth2) {
        sourceRegister = SOURCE_THIRD_PARTY;
        redirectUrl = this.appConfig.oauth2_info.redirect_uri;
        isLogin = true;
      }
      EventBus.$emit(SHOW_MODAL_LOGIN, redirectUrl, sourceRegister, isLogin, redirectAfterClose);
    },
    showModalSignup(redirectUrl = '', redirectAfterClose = '') {
      if (this.appConfig.isAvglDomain) {
        this.showModalOnboardingInitial(redirectUrl, redirectAfterClose);
      } else {
        this.showModalLogin(redirectUrl, '', false, redirectAfterClose);
      }
    },
    showModalOnboardingInitial() {
      EventBus.$emit(SHOW_MODAL_ONBOARDING_INITIAL);
    },
    showModalProvideGameNameAdminApp() {
      if (!this.isAuth) {
        if (this.appConfig.isAvglDomain) {
          this.showModalOnboardingInitial();
        } else {
          this.showModalLogin();
        }
      } else {
        EventBus.$emit(SHOW_MODAL_CONNECT_ACCOUNTS);
      }
    },
    showModalConnectSocialPlatform(redirectUrl) {
      EventBus.$emit(SHOW_MODAL_CONNECT_SOCIAL_PLATFORM, redirectUrl);
    },
    showModalBoomMsg(msg, title, textClose, action){
      EventBus.$emit(SHOW_MODAL_BOOM_MESSAGE, msg, title, textClose, action);
    },
    showModalBoomConfirm(msg, actionConfirm, btnOk, btnCancel, textNotice, actionCancel, allowCancel, redirectAfterClose = ''){
      EventBus.$emit(SHOW_MODAL_BOOM_CONFIRM, msg, actionConfirm, btnOk, btnCancel, textNotice, actionCancel, allowCancel, redirectAfterClose);
    },
    removeGameNameAccount(game, platform = '') {
      const self = this;
      this.$http
        .post(this.routeRemoveGameName(),
          { game: game, platform: platform },
          {
            headers: {
              'X-CSRF-TOKEN': this.appConfig.csrfToken,
            },
          }
        )
        .then((response) => {
          self.showModalBoomMsg(response.data.msg);
        })
        .catch(() => {
          self.showModalBoomMsg('Whoop! Something went wrong');
        })
        .finally(() => {

        });
    },
  },
};
