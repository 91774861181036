export const getters = {
  numberRewards: (state) => {
    return state.number_rewards;
  },
  rewardsReceived: (state) => {
    return state.rewards_received;
  },
  textNoReward: (state) => {
    return state.text_no_reward;
  },
  isShowDropsHighlight: (state) => {
    return state.is_show_drops_notification_highlight;
  },
};
