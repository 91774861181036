import tournamentService from './tournament.services';

export const actions = {
  setGamePlatform({ commit }, gamePlatform) {
    console.log('setGamePlatform');
    console.log(gamePlatform);
    commit('SET_GAME_PLATFORM', gamePlatform);
  },
  setVideoChatStatus({ commit }, status) {
    commit('SET_VIDEO_CHAT_STATUS', status);
  },
  setUserTournamentData({ commit }, userTournamentData) {
    commit('SET_USER_TOURNAMENT_DATA', userTournamentData);
  },
  setCurrentTournamentData({ commit }, currrentTournamentData) {
    commit('SET_CURRENT_TOURNAMENT_DATA', currrentTournamentData);
  },
  setLeftSideData({ commit }, leftSideData) {
    commit('SET_LEFT_SIDE_DATA', leftSideData);
  },
  setKillRaceStandingData({ commit }, killRaceStandingData) {
    commit('SET_KILL_RACE_STANDING_DATA', killRaceStandingData);
  },
  setBracketData({ commit }, bracketData) {
    commit('SET_BRACKET_DATA', bracketData);
  },
  setVideoStreamData({ commit }, videoStreamData) {
    commit('SET_VIDEO_STREAM_DATA', videoStreamData);
  },
  setCurrentTabTournament({ commit }, currentTab) {
    commit('SET_CURRENT_TAB_TOURNAMENT', currentTab);
  },
  setTeamInfo({ commit }, teamInfo) {
    commit('SET_TEAM_INFO', teamInfo);
  },
  setActionRegister({ commit }, actionRegisterData) {
    commit('SET_ACTION_REGISTER_DATA', actionRegisterData);
  },
  setModalRegister({ commit }, modalRegisterData) {
    commit('SET_MODAL_REGISTER_DATA', modalRegisterData);
  },
  setNextModalRegister({ commit }, nextModalRegister) {
    commit('SET_NEXT_MODAL_REGISTER', nextModalRegister);
  },
  setShowBrCustomStandings({ commit }, showCustomStading) {
    commit('SET_SHOW_BR_CUSTOM_STANDINGS', showCustomStading);
  },
  setHubTeamRequestData({ commit }, hubTeamRequestData) {
    commit('SET_HUB_TEAM_REQUEST_DATA', hubTeamRequestData);
  },
  async getMatchDetail({ commit }, data) {
    const tourId = data.tourId;
    const matchId = data.matchId;
    const typeDispute = data.typeDispute;
    const myTeamId = data.myTeamId;
    const matchDetail = await tournamentService.getMatchDetail(tourId, matchId, typeDispute, myTeamId);
    commit('SET_MATCH_DETAIL', matchDetail);
  },
};
