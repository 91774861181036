import { saveNotificationIfRedirectPage, setBoomAlertUserSeen } from '../../../utils/notifications';

export const mutations = {
  FETCH_NOTIFICATIONS: (state, data) => {
    data.notifications.map(notification => {
      const index = state.notifications.indexOf(notification);
      if (index === -1) {
        state.notifications.push(notification);
      }
    });
    data.notifications_viewed.map(notification => {
      const index = state.notifications_viewed.indexOf(notification);
      if (index === -1) {
        state.notifications_viewed.push(notification);
      }
    });
    state.total_not_viewed = data.not_viewed;
    state.page = data.page;
    state.limit = data.limit;
  },
  ADD_NEW_NOTIFICATION: (state, notification) => {
    state.notifications.unshift(notification);
    state.total_not_viewed++;
  },
  SET_ALL_NOTIFICATION_VIEWED: (state) => {
    state.notifications.map((notification) => {
      const index = state.notifications_viewed.indexOf(notification);
      if (index === -1) {
        state.notifications_viewed.push(notification);
      }
    });
    state.total_not_viewed = 0;
  },
  TOGGLE_NOTIFICATION_BOARD: (state, showing) => {
    if (!state.hover_notification_board) {
      state.show_notification_board = showing;
    }
  },
  HOVER_NOTIFICATION_BOARD: (state, hover) => {
    state.hover_notification_board = hover;
  },
  REMOVE_NOTIFICATION: (state, notification) => {
    if (!state.notifications_viewed.includes(notification)) {
      state.total_not_viewed--;
    }
    let index = state.notifications_viewed.indexOf(notification);
    if (index > -1) {
      state.notifications_viewed.splice(index, 1);
    }
    index = state.notifications.indexOf(notification);
    if (index > -1) {
      state.notifications.splice(index, 1);
    }
  },
  SET_IS_QUEUE_NOTIFICATION: (state, isQueue) => {
    state.is_queue_notification = isQueue;
  },
  SET_NOTIFICATIONS_QUEUE: (state, notifications) => {
    state.notifications_queued = notifications;
    saveNotificationIfRedirectPage(state.notifications_queued);
  },
  ADD_NOTIFICATION_TO_QUEUE: (state, notification) => {
    state.notifications_queued.push(notification);
    saveNotificationIfRedirectPage(state.notifications_queued);
  },
  ADD_NOTIFICATION_POPUP: (state, notification) => {
    state.notifications_popup.unshift(notification);
  },
  REMOVE_NOTIFICATION_POPUP: (state, notification) => {
    const index = state.notifications_popup.indexOf(notification);
    if (index > -1) {
      state.notifications_popup.splice(index, 1);
    }
  },
  ADD_NOTIFICATION_ANIMATION: (state, payload) => {
    state.notifications_animation.push(payload);
  },
  REMOVE_NOTIFICATION_ANIMATION: (state, notification) => {
    const index = state.notifications_animation.findIndex((item) => {
      return item.msg === notification;
    });
    if (index > -1) {
      state.notifications_animation.splice(index, 1);
    }
  },
  SET_BOOM_ALERTS: (state, alerts) => {
    state.boom_alerts = alerts;
  },
  ADD_BOOM_ALERT: (state, { timestamp, alert }) => {
    state.boom_alerts[timestamp] = alert;
  },
  SET_SEEN_BOOM_ALERT: (state, { username, timestamp }) => {
    delete state.boom_alerts[timestamp];
    setBoomAlertUserSeen(username, timestamp);
  },
};
