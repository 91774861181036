export const getters = {
  allNotifications: (state) => {
    return state.notifications;
  },
  countNotViewedNotification: (state) => {
    return state.total_not_viewed;
  },
  isShowingNotificationBoard: (state) => {
    return state.show_notification_board;
  },
  isHoveringNotificationBoard: (state) => {
    return state.hover_notification_board;
  },
  isQueueNotification: (state) => {
    return state.is_queue_notification;
  },
  allNotificationsQueued: (state) => {
    return state.notifications_queued;
  },
  notificationsPopup: (state) => {
    return state.notifications_popup;
  },
  notificationsAnimation: (state) => {
    return state.notifications_animation;
  },
  boomAlerts: (state) => {
    return state.boom_alerts;
  },
};
