import { mapGetters } from 'vuex';

export const providerAccountRoute = {
  computed: {
    ...mapGetters('app', ['appConfig']),
  },
  methods: {
    buildUrl(url, queries = {}) {
      let queryString = '';
      for (const [key, value] of Object.entries(queries)) {
        queryString += key + '=' + value + '&';
      }
      if (queryString.length > 0) {
        queryString = queryString.substr(0, queryString.length - 1);
      }
      if (queryString.length > 0) {
        url += '?' + queryString;
      }
      return url;
    },
    routeQueueupSessionsSection() {
      return `${this.appConfig.baseUrl}/profile#queue_sessions`;
    },
    routeQueueupOrdersSection() {
      return `${this.appConfig.baseUrl}/profile#queue_orders`;
    },
    routeQueueupLobbySessionsSection() {
      return `${this.appConfig.baseUrl}/profile#queue_lobby_sessions`;
    },
    routeQueueupSettings() {
      return `${this.appConfig.baseUrl}/profile#queue_up`;
    },
    routeProfileWallet() {
      return `${this.appConfig.baseUrl}/profile#wallet`;
    },
    routeQueueupEdit() {
      return `${this.appConfig.baseUrl}/profile#queue_edit_buddy`;
    },
    routeQbuddyRegister() {
      return `${this.appConfig.queueupUrl}/queueup/become-a-qbuddy`;
    },
    routeQueueupConsumerDashboard() {
      return `${this.appConfig.baseUrl}/queueup/dashboard`;
    },
    routeQueueupConsumerAllCoaches(gameAlias) {
      if (typeof gameAlias !== 'undefined') {
        return `${this.appConfig.baseUrl}/queueup/all-qbuddies#` + gameAlias;
      }
      return `${this.appConfig.baseUrl}/queueup/all-qbuddies`;
    },
    routeQbuddyPublicProfile(userName, isQPartner = false) {
      if (isQPartner === 1 || isQPartner === true) {
        return `${this.appConfig.queueupUrl}/queueup/qpartner/${userName}`;
      } else {
        return `${this.appConfig.queueupUrl}/queueup/qbuddy/${userName}`;
      }
    },
    routeQPartnerOrder(userName) {
      return `${this.appConfig.baseUrl}/queueup/qpartner/${userName}/order`;
    },

    // Provider Account Management Routes
    routePublicProviderProfile(userName) {
      return `${this.appConfig.baseUrl}/api/provider/${userName}/public`;
    },
    routeProviderAccountRegisterStatus() {
      return `${this.appConfig.baseUrl}/api/provider/is-registered`;
    },
    routeGetProviderOverview() {
      return `${this.appConfig.baseUrl}/api/provider/overview`;
    },
    routeGetProviderNextSessions() {
      return `${this.appConfig.baseUrl}/api/provider/next-sessions`;
    },
    routeGetProviderNextOrders() {
      return `${this.appConfig.baseUrl}/api/provider/next-orders`;
    },
    routeGetPastSessionsHistory(queries) {
      return this.buildUrl(`${this.appConfig.baseUrl}/api/provider/past-sessions-history`, queries);
    },
    routeGetPastOrdersHistory(queries) {
      return this.buildUrl(`${this.appConfig.baseUrl}/api/provider/past-orders-history`, queries);
    },
    routeQBuddyTransactionHistory(queries) {
      return this.buildUrl(`${this.appConfig.baseUrl}/api/provider/transactions-history`, queries);
    },
    routeProviderAccountRegister(queries) {
      return this.buildUrl(`${this.appConfig.baseUrl}/api/provider/register`, queries);
    },
    // End of PAM

    // Provider Service Order Management Routes
    routeProviderServiceOrderCreate(queries) {
      return this.buildUrl(`${this.appConfig.baseUrl}/api/provider-service-orders/create`, queries);
    },
    routeProviderServiceOrderAccept(orderId) {
      return `${this.appConfig.baseUrl}/api/provider-service-orders/accept?id=${orderId}`;
    },
    routeProviderServiceOrderCancel(orderId) {
      return `${this.appConfig.baseUrl}/api/provider-service-orders/cancel?id=${orderId}`;
    },
    routeProviderServiceOrderReject(orderId) {
      return `${this.appConfig.baseUrl}/api/provider-service-orders/reject?id=${orderId}`;
    },
    routeProviderServiceOrderReport(orderId) {
      return `${this.appConfig.baseUrl}/api/provider-service-orders/reject?id=${orderId}`;
    },
    routeProviderServiceOrderExtend(queries) {
      return this.buildUrl(`${this.appConfig.baseUrl}/api/provider-service-orders/extend`, queries);
    },
    routeProviderServiceOrderTip(queries) {
      return this.buildUrl(`${this.appConfig.baseUrl}/api/provider-service-orders/tip`, queries);
    },
    routeProviderLobbySessionCreate() {
      return `${this.appConfig.baseUrl}/api/provider-lobby-sessions/create`;
    },
    routeProviderLobbySessionUpdate() {
      return `${this.appConfig.baseUrl}/api/provider-lobby-sessions/update-session`;
    },
    routeGetAvailableGamesOfLobbySession() {
      return `${this.appConfig.baseUrl}/api/provider-lobby-sessions/get-available-games`;
    },
    routeGetAvailableSessionsOfLobbySession() {
      return `${this.appConfig.baseUrl}/api/provider-lobby-sessions/get-available-sessions`;
    },
    routeGetProviderGamesOfLobbySession() {
      return `${this.appConfig.baseUrl}/api/provider-lobby-sessions/get-provider-games`;
    },
    routeRegisterGameOfLobbySession() {
      return `${this.appConfig.baseUrl}/api/provider-lobby-sessions/register-game`;
    },
    routeGetMyLobbySessions() {
      return `${this.appConfig.baseUrl}/api/provider-lobby-sessions/get-my-sessions`;
    },
    routeRejectSpot() {
      return `${this.appConfig.baseUrl}/api/provider-lobby-sessions/reject-spot`;
    },
    routeStartGame() {
      return `${this.appConfig.baseUrl}/api/provider-lobby-sessions/start-game`;
    },
    routeEndGame() {
      return `${this.appConfig.baseUrl}/api/provider-lobby-sessions/complete-game`;
    },
    routeEndSession() {
      return `${this.appConfig.baseUrl}/api/provider-lobby-sessions/complete-session`;
    },
    cancelLobbySession() {
      return `${this.appConfig.baseUrl}/api/provider-lobby-sessions/cancel-session`;
    },
    routeRestartGame() {
      return `${this.appConfig.baseUrl}/api/provider-lobby-sessions/restart-game`;
    },
  },
};
