import userService from './user.service';

export const actions = {
  setUser({ commit }, user) {
    commit('SET_USER', user);
  },
  setTargetUser({ commit }, targetUser) {
    commit('SET_TARGET_USER', targetUser);
    commit('profile/SET_CURRENT_BOOM_ID', { boomAccountId: targetUser.id }, { root: true });
  },
  setUserAsTargetUser({ commit, state }) {
    commit('SET_USER_AS_TARGET_USER');
    commit('profile/SET_CURRENT_BOOM_ID', { boomAccountId: state.user.id }, { root: true });
  },
  setProfileSectionData({ commit }, data) {
    commit('SET_SECTION_DATA', data);
  },
  setExtraData({ commit }, data) {
    commit('SET_EXTRA_DATA', data);
  },
  setHubData({ commit }, data) {
    commit('SET_HUB_DATA', data);
  },
  setConnectPlatformData({ commit }, data) {
    commit('SET_CONNECT_PLATFORM_DATA', data);
  },
  updateUser({ commit }, data) {
    commit('UPDATE_USER', data);
  },
  updateExtraData({ commit }, data) {
    commit('UPDATE_EXTRA_DATA', data);
  },
  updateTargetUser({ commit }, data) {
    commit('UPDATE_TARGET_USER', data);
  },
  async setConnectedGames({ commit }, username) {
    const connectedGamesData = await userService.getListConnectedGames(username);
    commit('SET_CONNECTED_GAMES', connectedGamesData.listConnectedGames);
  },
  async setProfileSettings({ commit }, username) {
    const profileSettingsData = await userService.getProfileSetting(username);
    let profileSettings = {
      'hide_social_accounts': 0,
      'hide_stream_or_top_clips': 0,
      'is_private_profile': 0,
      'theme': 0,
      'receive_sms_notification': 1,
      'receive_discord_notification': 1,
      'receive_email_notification': 1,
    };
    if (profileSettingsData.profileSettings){
      profileSettings = profileSettingsData.profileSettings;
    }
    commit('SET_PROFILE_SETTINGS', profileSettings);
  },
  updateProfileSettings({ commit }, profileSettingsData) {
    let profileSettings = {
      'hide_social_accounts': 0,
      'hide_stream_or_top_clips': 0,
      'is_private_profile': 0,
      'theme': 0,
      'receive_sms_notification': 1,
      'receive_discord_notification': 1,
      'receive_email_notification': 1,
    };
    if (profileSettingsData){
      profileSettings = profileSettingsData;
    }
    commit('SET_PROFILE_SETTINGS', profileSettings);
  },
  async setSocialConnected({ commit }, username) {
    const socialConnectedData = await userService.getListSocialConnected(username);
    commit('SET_SOCIAL_CONNECTED', socialConnectedData.socialConnected);
  },
  updateSocialConnected({ commit }, socialConnected) {
    commit('SET_SOCIAL_CONNECTED', socialConnected);
  },
  updateConnectedGames({ commit }, data) {
    commit('SET_CONNECTED_GAMES', data);
  },
  async getListSupportGame({ commit }) {
    const listSupportGame = await userService.getListSupportGame();
    commit('GET_LIST_SUPPORT_GAME', listSupportGame.games);
  },
  updateListSupportGame({ commit }, listSupportGame) {
    commit('GET_LIST_SUPPORT_GAME', listSupportGame);
  },
  setProfileUserExtraData({ commit }, profileUserExtraData) {
    commit('SET_PROFILE_USER_EXTRA_DATA', profileUserExtraData);
  },
  updateProfileUserExtraData({ commit }, data) {
    commit('UPDATE_PROFILE_USER_EXTRA_DATA', data);
  },
};
