export const getters = {
  userData: (state) => {
    return state.user;
  },
  targetUserData: (state) => {
    return state.targetUser;
  },
  userSectionData: (state) => {
    return state.section_data;
  },
  userExtraData: (state) => {
    return state.extra_data;
  },
  userHubData: (state) => {
    return state.hub_data;
  },
  isAuth: (state) => {
    return state.user !== null && state.user.id !== undefined;
  },
  isQbuddyRegistered: (state) => {
    return state.extra_data !== null && state.extra_data.isQbuddyRegistered;
  },
  connectPlatformData: (state) => {
    return state.connect_platform_data;
  },
  connectedGames: (state) => {
    return state.connectedGames;
  },
  profileSettings: (state) => {
    return state.profileSettings;
  },
  socialConnected: (state) => {
    return state.socialConnected;
  },
  listSupportGame: (state) => {
    return state.listSupportGame;
  },
  profileUserExtraData: (state) => {
    return state.profileUserExtraData;
  },
};
