export const userType = {
  TWITCH: 0,
  TWITCH_TEMPORARY: 4,
  MIXER: 2,
  YOUTUBE: 3,
  EMAIL: 6,
  TWITTER: 5,
  GOOGLE: 7,
  APPLE: 8,
};

export const userEducationLevel = {
  USER_NONE_STUDENT: 0,
  USER_COLLEGE: 1,
  USER_HIGH_SCHOOL: 2,
  TEACHER_OR_ADMIN_OF_HIGH_SCHOOL_OR_DISTRICT: 3,
};

export const UserMixin = {
  data() {
    return {
      USER_TYPES: Object.freeze(userType),
      USER_EDUCATION_LEVEL: Object.freeze(userEducationLevel),
    };
  },
  methods: {

  },
};
