export const url_helper = {
  methods: {
    /**
     * check a string is url or not
     * @param str
     * @returns {boolean}
     */
    isValidURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(str);
    },
    /**
     * check url string is valid youtube link
     * https://youtube.com/watch?v={vId} or https://youtube.com/embed/{vId} or https://youtube.com/{channelId} (if allowLiveUrl = false this link is invalid)
     * @param url
     * @param allowLiveUrl
     * @returns {*|false}
     */
    checkLinkYoutubeValid(url, allowLiveUrl) {
      if (typeof allowLiveUrl === 'undefined') {
        allowLiveUrl = true;
      }
      const validWatchUrl = url.includes('/watch?v=');
      const validEmbedUrl = url.includes('/embed/');
      const validLiveUrl = url.includes('/channel/');
      return validWatchUrl || validEmbedUrl || (allowLiveUrl && validLiveUrl);
    },
    /**
     * return a embed youtube link
     * @param url
     * @param allowLiveUrl
     * @returns {URL}
     */
    getLinkYoutubeFromInput(url, allowLiveUrl) {
      let linkUrl = '';
      if (typeof allowLiveUrl === 'undefined') {
        allowLiveUrl = true;
      }
      const params = this.urlParameter(url);
      delete params['v'];
      if (typeof params['autoplay'] === 'undefined') {
        params['autoplay'] = 1;
      }
      if (url.includes('/channel/') && allowLiveUrl) {
        var linkArr = url.split('/');
        for (var i = linkArr.length - 1; i >= 0; i--) {
          var streamName = linkArr[i].toString();
          if (streamName.trim()) {
            linkUrl = 'https://www.youtube.com/embed/live_stream';
            params['channel'] = streamName.trim();
            break;
          }
        }
      } else if (url.includes('/watch?v=')) {
        const urlObj = new URL(url);
        var videoId = urlObj.searchParams.get('v');
        linkUrl = 'https://www.youtube.com/embed/' + videoId;
      } else {
        linkUrl = url.split('?')[0];
      }
      linkUrl = new URL(linkUrl);
      Object.keys(params).forEach(function(param) {
        linkUrl.searchParams.append(param, params[param]);
      });
      return linkUrl;
    },
    /**
     * return a embed facebook link
     * @param url
     * @returns {URL}
     */
    getLinkFacebook(url) {
      let linkUrl = '';
      linkUrl = 'https://www.facebook.com/plugins/video.php?href=' + url;
      linkUrl = new URL(linkUrl);
      return linkUrl;
    },
    /**
     * return object of paramaters from 1 url string
     * @param url
     * @returns {{}|null}
     */
    urlParameter(url) {
      const retObject = {};

      if (url.indexOf('?') === -1) {
        return null;
      }

      url = url.split('?')[1];

      const parameters = url.split('&');

      for (var i = 0; i < parameters.length; i++) {
        retObject[parameters[i].split('=')[0]] = parameters[i].split('=')[1];
      }

      return retObject;
    },
    /**
     * get youtube link by streamName (channel id or youtube username)
     * @param streamName
     * @returns {string}
     */
    async getLinkEmbedYoutubeFromChannelName(streamName) {
      let url;
      if (streamName.startsWith('UC')) {
        const params = {};
        params[streamName] = 'youtube';
        const responseData = await this.getLastestVideoChannel(params);
        const videoId = responseData[streamName];
        url = 'https://www.youtube.com/embed/' + videoId + '?autoplay=1&loop=1';
      } else {
        url = 'https://www.youtube.com/embed/?listType=user_uploads&list=' + streamName + '&autoplay=1&loop=1';
      }
      return url;
    },
    async getLastestVideoChannel(params) {
      const response = await this.$http.get(this.appConfig.baseUrl + '/youtube/get-lastest-video-channel', {
        params: {
          list_channel_id: JSON.stringify(params),
        },
      });
      return response.data.data;
    },
  },
};
