export const actions = {
  setConfig({ commit }, config) {
    commit('SET_CONFIG', config);
  },
  setErrors({ commit }, errors) {
    commit('SET_ERROR', errors);
  },
  setOldInput({ commit }, oldFormInput) {
    commit('SET_OLD_INPUT', oldFormInput);
  },
  setLaravelData({ commit }, data) {
    commit('SET_LARAVEL_DATA', data);
  },
  startLoadPage({ commit }) {
    commit('SET_START_LOAD_PAGE');
  },
  setPageLoaded({ commit }) {
    commit('SET_PAGE_LOADED');
  },
  setIsShowingLoginModal({ commit }, isShowing) {
    commit('SET_IS_SHOWING_LOGIN_MODAL', isShowing);
  },
  setIsShowingOnBoardingStudentModal({ commit }, isShowing) {
    commit('SET_IS_SHOWING_ON_BOARDING_STUDENT_MODAL', isShowing);
  },
  setIsShowingOnBoardingNonStudentModal({ commit }, isShowing) {
    commit('SET_IS_SHOWING_ON_BOARDING_NON_STUDENT_MODAL', isShowing);
  },
  updateLaravelData({ commit }, data) {
    commit('UPDATE_LARAVEL_DATA', data);
  },
  setShowMobileDeeplinkPopup({ commit }, show) {
    commit('SET_SHOW_MOBILE_DEEPLINK_POPUP', show);
  },
  setShowModalOpenInChrome({ commit }, show) {
    commit('SET_SHOW_MODAL_OPEN_IN_CHROME', show);
  },
};
