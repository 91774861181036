export const state = {
  hubData: {},
  hubUserData: {},
  currentTab: '',
  hubModal: {},
  list_hub_leader_info: {},
  list_hub_admin_info: {},
  list_hub_member_info: {},
  count_hub_leader: 0,
  count_hub_admin: 0,
  count_hub_member: 0,
  list_hub_team_manager_info: {},
  count_hub_team_manager: 0,
  list_hub_team_player_info: {},
  count_hub_team_player: 0,
  showModalConnectGameAccount: false,
  newVersionHubData: {},
};
