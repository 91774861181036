export const actions = {
  fetchNotifications({ commit }, notifications) {
    commit('FETCH_NOTIFICATIONS', notifications);
  },
  addNewNotification({ commit }, notification) {
    commit('ADD_NEW_NOTIFICATION', notification);
  },
  setAllNotificationViewed({ commit }) {
    commit('SET_ALL_NOTIFICATION_VIEWED');
  },
  toggleNotificationBoard({ commit }, showing) {
    commit('TOGGLE_NOTIFICATION_BOARD', showing);
  },
  hoverNotificationBoard({ commit }, hover) {
    commit('HOVER_NOTIFICATION_BOARD', hover);
  },
  removeNotification({ commit }, notification) {
    commit('REMOVE_NOTIFICATION', notification);
  },
  setIsQueueNotification({ commit }, isQueue) {
    commit('SET_IS_QUEUE_NOTIFICATION', isQueue);
  },
  setNotificationsQueue({ commit }, notifications) {
    commit('SET_NOTIFICATIONS_QUEUE', notifications);
  },
  addNotificationToQueue({ commit }, notification) {
    commit('ADD_NOTIFICATION_TO_QUEUE', notification);
  },
  addNotificationPopup({ commit }, notification) {
    commit('ADD_NOTIFICATION_POPUP', notification);
  },
  removeNotificationPopup({ commit }, notification) {
    commit('REMOVE_NOTIFICATION_POPUP', notification);
  },
  addNotificationAnimation({ commit }, payload) {
    commit('ADD_NOTIFICATION_ANIMATION', payload);
  },
  removeNotificationAnimation({ commit }, notification) {
    commit('REMOVE_NOTIFICATION_ANIMATION', notification);
  },
  setBoomAlerts({ commit }, alerts) {
    commit('SET_BOOM_ALERTS', alerts);
  },
  addBoomAlert({ commit }, payload) {
    commit('ADD_BOOM_ALERT', payload);
  },
  setSeenBoomAlert({ commit }, payload) {
    commit('SET_SEEN_BOOM_ALERT', payload);
  },
};
