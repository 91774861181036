export const state = {
  notifications: [],
  notifications_viewed: [],
  total_not_viewed: 0,
  show_notification_board: false,
  hover_notification_board: false,
  is_queue_notification: false,
  notifications_queued: [],

  // display notifications
  notifications_popup: [],
  notifications_animation: [],

  // boom alert
  boom_alerts: {},
};
