export const getters = {
  appConfig: (state) => {
    return state.config;
  },
  appErrors: (state) => {
    return state.errors;
  },
  appOldFormInput: (state) => {
    return state.oldInputForm;
  },
  laravelData: (state) => {
    return state.laravel_data;
  },
  isLoadingPage: (state) => {
    return state.is_loading_page;
  },
  isShowModalLogin: (state) => {
    return state.is_show_modal_login;
  },
  isShowModalOnboardingStudent: (state) => {
    return state.is_show_modal_onboarding_student;
  },
  isShowModalOnboardingNonStudent: (state) => {
    return state.is_show_modal_onboarding_non_student;
  },
  isShowMobileDeepLinkPopup: (state) => {
    return state.is_show_mobile_deep_link_popup;
  },
  isShowModalOpenInChrome: (state) => {
    return state.is_show_modal_open_in_chrome;
  },
};
