export const getters = {
  adminState: (state) => {
    return state.adminState;
  },
  adminSubMenu: (state) => {
    return state.adminSubMenu;
  },
  adminCreatorData: (state) => {
    return state.adminCreatorData;
  },
  bracketData: (state) => {
    return state.bracketData;
  },
  killRaceData: (state) => {
    return state.killRaceData;
  },
  adminModal: (state) => {
    return state.adminModal;
  },
  tournamentSettingImport: (state) => {
    return state.tournamentSettingImport;
  },
};
