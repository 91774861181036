export const mutations = {
  SET_NUMBER_REWARDS: (state, number) => {
    state.number_rewards = number;
  },
  INCREASE_NUMBER_REWARDS: (state, number) => {
    state.number_rewards += number;
  },
  SET_REWARDS_RECEIVED: (state, data) => {
    state.rewards_received = data;
  },
  SET_NO_REWARD_TEXT: (state, text) => {
    state.text_no_reward = text;
  },
  CLEAR_REWARDS_RECEIVED: (state) => {
    state.rewards_received = [];
    state.text_no_reward = '';
  },
  SET_SHOW_DROPS_HIGHLIGHT: (state, isShowing) => {
    state.is_show_drops_notification_highlight = isShowing;
  },
};
